body {
	counter-reset: section;
}

h4::before {
	color: #e14954;
	counter-increment: section;
	content: counter(section) ". ";
}

.service ul,.service li{
	list-style: inside!important;
}

.service p {
	color: #8e9baf;
}